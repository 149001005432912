<template>
  <v-container>
    <v-dialog
        ref="contentdialog"
        v-model="$store.state.photogalleryDialogOpen"
        persistent
        scrollable
        max-width="800"
    >
        <v-card
        elevation="8"
        >
            <v-card-title class="headline">
                {{ dialogTitle }}
            </v-card-title>
            <v-card-text>
                <v-form @:submit.prevent>
                    <v-text-field
                        id="title"
                        v-model="currentPhotogallery.title"
                        :error-messages="titleErrors"
                        type="text"
                        required
                        outlined
                        placeholder="Titolo"
                        label="Titolo"
                        @input="$v.currentPhotogallery.title.$touch()"
                        @blur="$v.currentPhotogallery.title.$touch()"
                    />
                    <v-textarea
                        id="abstract"
                        v-model="currentPhotogallery.abstract"
                        :error-messages="abstractErrors"
                        required
                        auto-grow
                        outlined
                        placeholder="Abstract"
                        label="Abstract"
                        @input="$v.currentPhotogallery.abstract.$touch()"
                        @blur="$v.currentPhotogallery.abstract.$touch()"
                    />
                  <v-card-text> Date permanenza sul sito </v-card-text>
                  <v-row>
                    <v-col>
                      Inizio: {{ sitestartHuman }}
                      <v-btn
                        icon
                        small
                        @click="sitestartShow = true"
                      >
                        <v-icon> mdi-pencil </v-icon>
                      </v-btn>
                    </v-col>
                    <v-col>
                      Fine: {{ siteendHuman }}
                      <v-btn
                        icon
                        small
                        @click="siteendShow = true"
                      >
                        <v-icon> mdi-pencil </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-date-picker
                        v-show="sitestartShow"
                        v-model="currentPhotogallery.sitestart"
                        locale="it-it"
                        @input="closesitestartDialog"
                      />
                    </v-col>
                    <v-col>
                      <v-date-picker
                        v-show="siteendShow"
                        v-model="currentPhotogallery.siteend"
                        locale="it-it"
                        @input="closesiteendDialog"
                      />
                    </v-col>
                  </v-row>
                  <FileDrop
                    :currentPhotogallery="currentPhotogallery"
                    @filechanged="addFile"
                    @startedupload="fileUploading"
                  />
                  <v-row
                    v-if="filesUploading > 0"
                    justify="center"
                  >
                    <v-spacer />
                    <v-col cols="1">
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      />
                    </v-col>
                    <v-spacer />
                  </v-row>

                  <v-row
                    class="text-sm p-1"
                    align="start"
                  >
                    <v-col
                      v-for="(file,i) in currentPhotogallery.pictures"
                      :key="i"
                      cols="2"
                      align-self="start"
                    >
                      <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-img
                          max-width="40"
                          max-height="70"
                          :src="backendURL + '/pictures/' + file.id"
                          style="display: inline-block;"
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                        <v-img
                          :src="backendURL + '/pictures/' + file.id"
                          width="400"
                          contain
                        />
                      </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          title="Elimina"
                          v-bind="attrs"
                          v-on="on"
                          @click="currentPhotogallery.pictures.splice(i, 1)"
                        >
                      <v-icon small>
                        mdi-trash-can-outline
                      </v-icon>
                    </v-btn>
                    </template>
                    <span>Elimina</span>
                  </v-tooltip>
                  </v-col>
                  </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn
                color="green darken-1"
                :disabled="buttonDisabled"
                @click="onSubmit()"
                >
                {{ dialogButtonText }}
                </v-btn>
                <v-btn
                    color="green darken-1"
                    @click="$store.state.photogalleryDialogOpen = false"
                >
                Annulla
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import FileDrop from './FileDrop.vue'
const dateFormat = require('dateformat')
const config = require('@/config')
const axios = require('axios')
export default {
    name: 'PhotogalleryDialog',
    components: { FileDrop },
    mixins: [validationMixin],
    props: {
      currentPhotogallery: { type: Object, default: null },
      dialogTitle: { type: String, default: '' },
      dialogButtonText: { type: String, default: '' },
    },
    validations: {
      currentPhotogallery: {
        title: { required },
        abstract: { required },
      },
    },
    data: () => ({
        buttonDisabled: true,
        filelist: [],
        backendURL: config.backendURL,
        maxfilesupload: 0,
        filesUploading: 0,
        sitestartShow: false,
        siteendShow: false,
    }),
    computed: {
      sitestartHuman: {
        get: function () {
          return dateFormat(new Date(this.currentPhotogallery.sitestart), 'd mmmm yyyy')
        },
        set: function () {
        },
      },
      siteendHuman: {
        get: function () {
          return dateFormat(new Date(this.currentPhotogallery.siteend), 'd mmmm yyyy')
        },
        set: function () {
        },
      },
      localdialogOpen: {
        get: function () {
          return this.$store.state.photogalleryDialogOpen
        },
      },
      titleErrors: {
        get: function () {
          const errors = []
          if (!this.$v.currentPhotogallery.title.$dirty) return errors
          !this.$v.currentPhotogallery.title.required && errors.push('Titolo necessario')
          this.checkDialogErrors()
          return errors
        },
        set: function () {
        },
      },
      abstractErrors: {
        get: function () {
          const errors = []
          if (!this.$v.currentPhotogallery.abstract.$dirty) return errors
          !this.$v.currentPhotogallery.abstract.required && errors.push('Abstract necessario')
          this.checkDialogErrors()
          return errors
        },
        set: function () {
        },
      },
    },
    watch: {
      localdialogOpen: function (val) {
        this.checkDialogErrors()
      },
    },
    methods: {
        closesitestartDialog () {
          this.sitestartShow = false
        },
        closesiteendDialog () {
          this.siteendShow = false
        },
        addFile (receivedFile) {
          var component = this
          component.currentPhotogallery.pictures.push(receivedFile)
          component.filesUploading -= 1
          if (component.filesUploading === 0) {
            // TODO messaggio di fine upload
            component.$emit('update-snack', { snackbar: true, snackbarText: 'Caricate ' + component.maxfilesupload + ' immagini', snackbarColor: 'primary' })
            component.maxfilesupload = 0
          }
        },
        fileUploading () {
          var component = this
          component.filesUploading += 1
          if (component.filesUploading > component.maxfilesupload) {
            component.maxfilesupload = component.filesUploading
          }
        },
        noAction (event) {
          event.preventDefault()
        },
        onSubmit () {
          var component = this
          var currentJWT = localStorage.getItem(config.authToken)
          if (component.currentPhotogallery.id) {
            // edit photogallery
            axios
            .put(config.backendURL + '/photogalleries', component.currentPhotogallery, {
              headers: {
                authorization: 'Bearer ' + currentJWT,
              },
            })
            .then(function (response) {
              if (response.data.httpStatus === 200) {
                localStorage.setItem(config.authToken, response.data.jwt)
                component.$store.state.photogalleryDialogOpen = false
                component.$emit('customevent', null)
              }
            })
            .catch(function (response) {
              console.log(response)
            })
          } else {
            // create photogallery
            axios
            .post(config.backendURL + '/photogalleries', component.currentPhotogallery, {
              headers: {
                authorization: 'Bearer ' + currentJWT,
              },
            })
            .then(function (response) {
              if (response.data.httpStatus === 200) {
                localStorage.setItem(config.authToken, response.data.jwt)
                component.$store.state.photogalleryDialogOpen = false
                component.$emit('customevent', null)
              }
            })
            .catch(function (response) {
              console.log(response)
            })
          }
        },
        checkDialogErrors () {
        if (
          this.$v.currentPhotogallery.title.required &&
          this.$v.currentPhotogallery.abstract.required
        ) {
          this.buttonDisabled = false
        } else {
          this.buttonDisabled = true
        }
      },
    },
}
</script>
<style scoped>
.col .v-btn {
  vertical-align: top;
  margin-top: 0px;
}
</style>

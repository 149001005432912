<template>
    <v-container>
      <v-snackbar
        v-model="snack.snackbar"
        timeout="3000"
        :color="snack.snackbarColor"
      >
      {{ snack.snackbarText }}
      </v-snackbar>
      <PhotogalleryDialog
        :currentPhotogallery="currentPhotogallery"
        :dialogTitle="dialogTitle"
        :dialogButtonText="dialogButtonText"
        @customevent="getPhotogalleries"
        @update-snack="updateSnack"
      />
      <v-dialog
          v-model="deleteConfirm"
          persistent
          scrollable
          max-width="400"
        >
          <v-card>
            <v-card-title>
              Conferma cancellazione
            </v-card-title>
            <v-card-text
              v-html="deleteText"
            />
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="green darken-1"
                @click="deletePhotogalleryExec"
              >
                OK
              </v-btn>
              <v-btn
                color="green darken-1"
                @click="deleteConfirm = false"
              >
                Annulla
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      <v-row
          justify="center"
        >
          <v-spacer />
          <v-col cols="2">
            <v-btn
              dark
              @click="addPhotogallery()"
              >
              NuovA
            </v-btn>
          </v-col>
          <v-spacer />
      </v-row>
      <v-row
      justify="center"
    >
      <v-col
        :cols="$isMobile()? 12 : 8"
      >
        <v-card
          elevation="10"
        >
          <v-data-table
            elevation="10"
            :headers="headers"
            :items="photogalleries"
            item-key="title"
            class="elevation-1"
            :search="search"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-minus',
              nextIcon: 'mdi-plus',
              itemsPerPageText: 'Photogalleries per pagina',
              itemsPerPageAllText: 'Tutte le photogalleries',
              itemsPerPageOptions: [5, 25, -1],
              pageText: '',
            }"
          >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              label="Filtra..."
              class="mx-4"
              itemsPerPageText="'Photogalleries per pagina'"
            />
          </template>
          <template v-slot:no-data>
            <v-sheet>
              Nessuna photogallery
            </v-sheet>
          </template>
          <template v-slot:no-results>
            <v-sheet>
              Nessuna photogallery
            </v-sheet>
          </template>
          <template v-slot:item.id="{ item }">
            <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click="editPhotogallery(item)"
                    >
                      <v-icon small>
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Modifica</span>
                </v-tooltip>
            <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click="deletePhotogallery(item)"
                    >
                      <v-icon small>
                        mdi-trash-can-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Elimina</span>
                </v-tooltip>
         </template>
         </v-data-table>
        </v-card>
      </v-col>
      </v-row>
    </v-container>
</template>
<script>
  import PhotogalleryDialog from './PhotogalleryDialog.vue'
  const config = require('@/config')
  const axios = require('axios')
  const dateFormat = require('dateformat')
  dateFormat.i18n = {
    dayNames: [
      'Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab',
      'Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato',
    ],
    monthNames: [
      'Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic',
      'Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre',
    ],
    timeNames: [
      'a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM',
    ],
  }
  export default {
    name: 'Photogalleries',
    components: { PhotogalleryDialog },
    data: () => ({
      dialogTitle: '',
      dialogButtonText: '',
      photogalleries: [],
      currentPhotogallery: {
        title: '',
        abstract: '',
        pictures: [],
        sitestart: new Date().toISOString().substring(0, 10).toString(),
        siteend: new Date().toISOString().substring(0, 10).toString(),
      },
      search: '',
      snack: {
        snackbar: false,
        snackbarText: '',
        snackbarColor: 'primary',
      },
      deleteId: null,
      deleteConfirm: false,
      deleteText: '',
    }),
    computed: {
      headers () {
        return [
          {
            text: 'Titolo',
            align: 'start',
            sortable: true,
            value: 'title',
          },
          {
            text: '',
            align: 'end',
            sortable: false,
            value: 'id',
          },
        ]
      },
    },
    created: function () {
      this.getPhotogalleries()
    },
    methods: {
      deletePhotogallery (item) {
        var component = this
        component.deleteConfirm = true
        component.deleteText = 'Sei sicuro di voler rimuovere la photogallery &laquo;' + item.title + '&raquo;'
        component.deleteId = item.id
        component.deleteConfirm = true
      },
      deletePhotogalleryExec () {
        var component = this
        var currentJWT = localStorage.getItem(config.authToken)
        axios
          .delete(config.backendURL + '/photogalleries/' + component.deleteId, {
            headers: {
              authorization: 'Bearer ' + currentJWT,
            },
          })
          .then(function (response) {
            component.deleteConfirm = false
            if (response.data.httpStatus === 200) {
              component.getPhotogalleries()
              localStorage.setItem(config.authToken, response.data.jwt)
              component.snack.snackbarText = 'Photogallery rimossa correttamente'
              component.snack.snackbarColor = 'primary'
              component.snack.snackbar = true
            } else {
              component.snack.snackbarText = response.data.longMessage
              component.snack.snackbarColor = 'error'
              component.snack.snackbar = true
            }
          })
          .catch(function (response) {
            console.log(response)
            component.deleteConfirm = false
          })
      },
      editPhotogallery (photogallery) {
        this.currentPhotogallery = photogallery
        this.dialogTitle = 'Modifica photogallery'
        this.dialogButtonText = 'Salva'
        this.$store.state.photogalleryDialogOpen = true
      },
      getPhotogalleries () {
        var component = this
        var currentJWT = localStorage.getItem(config.authToken)
        // var payload = jwt.decode(currentJWT)
        axios
          .get(config.backendURL + '/adminphotogalleries', {
            headers: {
              authorization: 'Bearer ' + currentJWT,
            },
          })
          .then(function (response) {
            component.photogalleries = response.data.photogalleries
            localStorage.setItem(config.authToken, response.data.jwt)
          })
          .catch(function (response) {
            console.log(response)
          })
      },
      updateSnack (snack) {
        this.snack = snack
      },
      addPhotogallery () {
        this.currentPhotogallery = {
          id: null,
          title: '',
          abstract: '',
          pictures: [],
          sitestart: new Date().toISOString().substring(0, 10).toString(),
          siteend: new Date().toISOString().substring(0, 10).toString(),
        }
        this.dialogTitle = 'Nuova photogallery'
        this.dialogButtonText = 'Aggiungi'
        this.$store.state.photogalleryDialogOpen = true
      },
    },
  }
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-snackbar',{attrs:{"timeout":"3000","color":_vm.snack.snackbarColor},model:{value:(_vm.snack.snackbar),callback:function ($$v) {_vm.$set(_vm.snack, "snackbar", $$v)},expression:"snack.snackbar"}},[_vm._v(" "+_vm._s(_vm.snack.snackbarText)+" ")]),_c('PhotogalleryDialog',{attrs:{"currentPhotogallery":_vm.currentPhotogallery,"dialogTitle":_vm.dialogTitle,"dialogButtonText":_vm.dialogButtonText},on:{"customevent":_vm.getPhotogalleries,"update-snack":_vm.updateSnack}}),_c('v-dialog',{attrs:{"persistent":"","scrollable":"","max-width":"400"},model:{value:(_vm.deleteConfirm),callback:function ($$v) {_vm.deleteConfirm=$$v},expression:"deleteConfirm"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Conferma cancellazione ")]),_c('v-card-text',{domProps:{"innerHTML":_vm._s(_vm.deleteText)}}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1"},on:{"click":_vm.deletePhotogalleryExec}},[_vm._v(" OK ")]),_c('v-btn',{attrs:{"color":"green darken-1"},on:{"click":function($event){_vm.deleteConfirm = false}}},[_vm._v(" Annulla ")])],1)],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-spacer'),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"dark":""},on:{"click":function($event){return _vm.addPhotogallery()}}},[_vm._v(" NuovA ")])],1),_c('v-spacer')],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":_vm.$isMobile()? 12 : 8}},[_c('v-card',{attrs:{"elevation":"10"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"elevation":"10","headers":_vm.headers,"items":_vm.photogalleries,"item-key":"title","search":_vm.search,"footer-props":{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-minus',
          nextIcon: 'mdi-plus',
          itemsPerPageText: 'Photogalleries per pagina',
          itemsPerPageAllText: 'Tutte le photogalleries',
          itemsPerPageOptions: [5, 25, -1],
          pageText: '',
        }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Filtra...","itemsPerPageText":"'Photogalleries per pagina'"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"no-data",fn:function(){return [_c('v-sheet',[_vm._v(" Nessuna photogallery ")])]},proxy:true},{key:"no-results",fn:function(){return [_c('v-sheet',[_vm._v(" Nessuna photogallery ")])]},proxy:true},{key:"item.id",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.editPhotogallery(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Modifica")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.deletePhotogallery(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-trash-can-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Elimina")])])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
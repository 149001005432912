<template>
    <v-sheet
      id="dropzone"
      ref="dzone"
      rounded=""
      tabindex="0"
      color="indigo lighten-4"
      width="100%"
      style="cursor:pointer;"
      min-height="150"
      class="pa-2 rounded-xl"
      @click="uploadClicked"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
    <v-file-input
        id="assetsFieldHandle"
        ref="fileUpload"
        type="file"
        multiple
        style="visibility: hidden;"
        accept=".jpg,.jpeg,.png"
        @change="onChange"
    />
    <v-row justify="center">
        <v-icon
          color="indigo darken-2"
          size="75"
        >
          mdi-cloud-upload-outline
        </v-icon>
      </v-row>
      <v-row justify="center">
        <span class="title indigo--text text--darken-2 text-center">Trascina qui le immagini da caricare<br>(o fai click per selezionarle)</span>
      </v-row>
  </v-sheet>
</template>
<script>
const axios = require('axios')
const config = require('@/config')
export default {
    name: 'FileDrop',
    data: () => ({
        filelist: [],
    }),
    mounted () {
    },
    methods: {
      uploadClicked () {
        this.$refs.fileUpload.$refs.input.click()
      },
      async uploadPicture (file) {
        var component = this
        var formData = new FormData()
        formData.append('file', file)
        var currentJWT = localStorage.getItem(config.authToken)
        component.$emit('startedupload', { name: file.name })
        axios
          .post(config.backendURL + '/pictures', formData, {
            headers: {
              authorization: 'Bearer ' + currentJWT,
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(function (response) {
            if (response.data.httpStatus === 200) {
              localStorage.setItem(config.authToken, response.data.jwt)
              component.$emit('filechanged', { id: response.data.pictureid, name: file.name })
            }
          })
          .catch(function (response) {
            console.log(response)
          })
      },
      onChange () {
        var component = this
        // this.$emit('filechanged', [...this.$refs.file.files])
        component.$refs.fileUpload.$refs.input.files.forEach(element => {
          component.uploadPicture (element)
        })
      },
      remove (i) {
      this.filelist.splice(i, 1)
      },
      dragover (event) {
        event.preventDefault()
          // Add some visual fluff to show the user can drop its files
      },
      dragleave (event) {
          // Clean up
      },
      drop (event) {
        var component = this
          event.preventDefault()
          component.$refs.fileUpload.files = event.dataTransfer.files
          // Clean up
          event.dataTransfer.files.forEach(element => {
              component.uploadPicture (element)
          })
      },
    },
}
</script>
